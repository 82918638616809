import React from 'react';
import { Formik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    sectionBox,
    grid,
    loading,
    topWrapper,
    title,
    container,
    tableGrid,
    loader,
    header,
} from './candidate-recommendations-listing.module.scss';
import { ISection } from '../../models/section.model';
import { ICandidateRecommendation } from '../../models/candidate-recommendation';
import { IFilter, IFilters } from '../../models/filter.model';
import { TLocale } from '../../locale';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { useList } from '../../hooks/use-list';
import { getUserTokenData } from '../../utils/get-user-token-data';
import useTranslations from '../../hooks/use-translations';
import { getNodes } from '../../utils/get-nodes';

import Section from '../hoc/section';
import HandleFormikChange from '../hoc/handle-formik-change';
import Tooltip from '../atoms/tooltip';
import Loader from '../atoms/loader';
import Filters from '../organisms/filters';
import Table, { ITableProps } from '../organisms/table';
import DashboardHeader from '../molecules/dashboard-header';
import CandidateTestButton from '../atoms/candidate-test-button';
import { useCandidate } from '../../hooks/use-candidate';

interface ICandidateRecommendationsListingProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

interface ICandidateRecommendationsListingQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
}

const CandidateRecommendationsListing: React.FC<ICandidateRecommendationsListingProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const { style, sectionId, css } = section;
    const token = getUserTokenData();
    const t = useTranslations('CandidateRecommendationsListing');
    const candidate = useCandidate();
    const { locale } = usePageContext();

    const { allPage } = useStaticQuery<ICandidateRecommendationsListingQueryResult>(query);
    const pages = getNodes(allPage);
    const candidateSuggestionsPage = pages.find((page) => page.locale === locale)?.pathname || '';

    const tooltipInfo = candidate.data?.testRetryWaitForAdmin
        ? t.hintWaiting
        : candidate.data?.testRetryUrl
        ? t.hintRetry
        : t.hintBuy;

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        filters,
        handleChange,
        pagination,
        values,
        sort,
    } = useList<ICandidateRecommendation>({
        endpoint: '/candidates/recommendation-list',
        token: token?.token,
        perPage: 6,
        sort: '-score',
    });

    return (
        <Section
            className={`${sectionBox} ${className} ${status === 'loading' ? loading : ''}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <Formik onSubmit={() => {}} initialValues={values || {}} enableReinitialize={true}>
                    {(formik) => (
                        <div className={container}>
                            <HandleFormikChange onChange={handleChange} />
                            <div className={header}>
                                <div className={topWrapper}>
                                    <DashboardHeader className={title} TitleTag={TitleTag}>
                                        {t.title}
                                    </DashboardHeader>
                                    {filters && <Filters filters={getNewFilters(filters, t)} />}
                                    <Tooltip>{tooltipInfo}</Tooltip>
                                </div>
                                <CandidateTestButton />
                            </div>
                            <Table
                                status={status}
                                paginationPaths={paginationPaths}
                                tableClassName={tableGrid}
                                totalCount={pagination?.totalCount || 0}
                                headerCells={getHeaderCells(t)}
                                rows={getRecommendationRows(t, items, candidateSuggestionsPage)}
                                sort={sort}
                                formik={formik}
                                noItemsText={t.noItems}
                            />
                        </div>
                    )}
                </Formik>
            )}
        </Section>
    );
};

const getNewFilters = (
    filters: IFilters | null | undefined,
    t: TLocale['CandidateRecommendationsListing']
) => {
    const newFilters = { ...filters };
    let positionFilter: IFilter | null | undefined = undefined;

    if (filters) {
        positionFilter = Object.values(filters).find((filter) => filter.paramName === 'position');
        const positionIndex = Object.keys(filters).find((key) => filters[key] === positionFilter);

        if (positionFilter && positionIndex) {
            positionFilter.label = t.positionPlaceholder;
        }
    }
    return {
        ...newFilters,
    };
};

function getHeaderCells(t: TLocale['CandidateRecommendationsListing']): ITableProps['headerCells'] {
    return [{ label: t.position }, { label: t.match, field: 'score' }, { label: '' }];
}

function getRecommendationRows(
    t: TLocale['CandidateRecommendationsListing'],
    recommendations: ICandidateRecommendation[],
    candidateSuggestionsPage: string
): ITableProps['rows'] {
    return recommendations.map((recommendation) => {
        return [
            {
                type: 'data',
                label: t.position,
                value: recommendation.name,
            },
            {
                type: 'data',
                label: t.match,
                value: `${recommendation.score ? recommendation.score.toString() : 0}%`,
                valueStyle: 'accent',
            },
            {
                type: 'action',
                actions: [
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        to: `${candidateSuggestionsPage}?position=${recommendation.positionId}`,
                        children: t.apply,
                    },
                ],
            },
        ];
    });
}

export const query = graphql`
    query {
        allPage(filter: { type: { eq: "candidate-suggestions" } }) {
            edges {
                node {
                    pathname
                    locale
                }
            }
        }
    }
`;

export default CandidateRecommendationsListing;
