// extracted by mini-css-extract-plugin
export var backgroundAnimation = "candidate-recommendations-listing-module--backgroundAnimation--8d7dc";
export var container = "candidate-recommendations-listing-module--container--25579";
export var filters = "candidate-recommendations-listing-module--filters--57644";
export var grid = "candidate-recommendations-listing-module--grid--b71eb";
export var header = "candidate-recommendations-listing-module--header--6f95b";
export var loader = "candidate-recommendations-listing-module--loader--0d96f";
export var loading = "candidate-recommendations-listing-module--loading--488ab";
export var sectionBox = "candidate-recommendations-listing-module--section-box--393ed";
export var tableGrid = "candidate-recommendations-listing-module--table-grid--ec8a9";
export var title = "candidate-recommendations-listing-module--title--f0a2e";
export var topWrapper = "candidate-recommendations-listing-module--top-wrapper--1ab32";